<template>
<div>
  <template>
    <div id="breadcrumb-alignment" class="mb-1">
      <div class="d-flex justify-content-start breadcrumb-wrapper">
        <b-breadcrumb
          :items="breadCrumb()"
        />
      </div>
    </div>
  </template>

  <b-row class="match-height">
    <b-col
      lg="12"
      md="12"
    >
   
    <b-card
      title="Add Form">
      <b-alert
        v-model="showDismissibleAlert"
        variant="danger"
        dismissible
        class="mb-1"
      >
        <div class="alert-body">
          {{error_message}}
        </div>
      </b-alert>
      
      <b-form @submit="formSubmit">
        
        <b-row>
          
          <b-col md="12">

            <b-row>
              <b-col
                cols="12"
                md="12"
              >
                <div class="d-flex align-items-center justify-content-end">
                  
                  <b-button
                    variant="danger"
                    align="right"
                    @click="sampleDownload"
                  >
                    <span class="text-nowrap">Download Sample</span>
                  </b-button>
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="12">

                <b-form-group
                  label="Form Type"
                  class="required"
                >


                  <!-- <div class="demo-inline-spacing">
                    <b-form-radio
                      class="mb-0 mt-1"
                      
                      name="some-radios"
                      value="QI"
                      v-model="form.type"
                    >
                      Quality Inspection
                    </b-form-radio>

                    <b-form-radio
                      class="mb-0 mt-1"
                      
                      name="some-radios"
                      value="checklist"
                      v-model="form.type"
                    >
                      Checklist
                    </b-form-radio>

                    <b-form-radio
                      class="mb-0 mt-1"
                      
                      name="some-radios"
                      value="qi_checklist"
                      v-model="form.type"
                    >
                      Quality Inspection and Checklist
                    </b-form-radio>

                    <b-form-radio
                      class="mb-0 mt-1"
                      
                      name="some-radios"
                      value="QA"
                      v-model="form.type"
                    >
                      Quality Audit Checklist
                    </b-form-radio>
                  </div> -->


                  <b-form-select v-model="form.type" >

                    <!-- <b-form-select-option value="">Select</b-form-select-option> -->
                    <b-form-select-option value="QI">Quality Inspection</b-form-select-option>
                    <b-form-select-option value="checklist">Checklist</b-form-select-option>
                    <b-form-select-option value="qi_checklist">Quality Inspection and Checklists</b-form-select-option>
                    <b-form-select-option value="QA">Quality Audit</b-form-select-option>

                  </b-form-select>
                
            

                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="12" >
                
                <b-form-group
                  label="Project Site"
                  class="required"

                >
                  <b-form-select v-model="form.site" @change="resetSupervisor()">

                    <b-form-select-option value="" disabled>Select</b-form-select-option>
                    
                    <b-form-select-option :value="site._id" v-for="site in sites" :key="site._id">{{site.site_name | capitalize}}</b-form-select-option>
                    
                    
                  </b-form-select>
                    
                </b-form-group>
              </b-col>

            </b-row>

            <b-row>
              <b-col md="12">
                <b-form-group
                  label="Form Name"
                  class="required"
                >
                  <b-form-input
                    placeholder="3 to 50 characters"
                    v-model="form.form_name"
                    autocomplete="off"
                  />
                </b-form-group>
              </b-col>

              
            </b-row>
            <b-row>
              <b-col md="12" >
                
                <b-form-group
                  label="Users"
                  class="required"

                >
                  <v-select
                    v-model="form.users"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    multiple
                    label="full_name"
                    :options="supervisors"
                    placeholder="Select"
                    :close-on-select=false
                    :clear-on-select=false
                    class="multiselect_muliple_options"
                  />
                    
                </b-form-group>
              </b-col>

            </b-row>

            <b-row>
              <b-col md="12" >

                <b-form-group
                  label="File Template"
                  class="required margin_bottom_zero_mobile"
                >

                  <div class="demo-inline-spacing mb-1">
                      <b-form-radio
                        class="mb-0 mt-0"
                        @change="resetData()"
                        name="some-radios"
                        value="import"
                        v-model="template"
                      >
                        Import
                      </b-form-radio>

                      <b-form-radio
                        class="mb-0 mt-0"
                        @change="resetData()"
                        name="some-radios"
                        value="create"
                        v-model="template"
                      >
                        Create
                      </b-form-radio>

                      
                  </div>
                </b-form-group>
                
              </b-col>

            </b-row>

            <b-row class="mb-2" v-if="template == 'import'">
              <b-col md="12">
                <b-form-group
                  label="Upload File Template"
                  
                  class="required"
                >
                  <b-form-file
                    id="upload_file"
                    ref="uploadFile"
                    accept=".xls, .xlsx, .csv"
                    @change="onFileChange"
                    v-model="bulkFile"
                  />
                  <small class="text-danger">supported format: .xls or .xlsx</small>
                </b-form-group>
              </b-col>
            </b-row>

            <div class="inner_dv" v-if="template == 'create'">
              <legend :class="$store.state.appConfig.layout.skin == 'dark' ? 'legend_upper_border' : 'light_legend_upper_border'" style="width: 250px" >Form Template Detail</legend>
                <div :class="$store.state.appConfig.layout.skin == 'dark' ? 'legen_inc_div' : 'light_legen_inc_div'">

                <div>

                  <b-row class="mt-1 mb-1">

                    <b-col md="5">
                      <h5>Inspection Unit</h5>
                    </b-col>

                    <b-col md="5">
                      <h5>Elements</h5>
                    </b-col>

                    <b-col md="2">
                        
                    </b-col>

                  </b-row>

                  <b-row class="mb-1" v-for="(data,i) in form.data" :key="i">

                    <b-col md="4">
                      <b-form-input
                        placeholder="Unit Name"
                        v-model="data.unit_name"
                        autocomplete="off"
                      />
                    </b-col>
                    <b-col md="1">
                        <b-button
                          variant="danger"
                          class="mt-0 ml-50 mr-0 float-right"
                          @click="removeUnit(i)"
                        >
                          <feather-icon icon="XIcon" />
                          <!-- <span class="align-middle ml-50">Unit</span> -->
                        </b-button>
                    </b-col>

                    <b-col md="7">

                      <div v-for="(e,k) in data.elements">
                        <b-row class="mb-1">
                          <b-col md="9">
                            <b-form-input
                              placeholder="Element Name"
                              v-model="e.name"
                              autocomplete="off"
                            />
                          </b-col>
                          <b-col md="3">
                            <b-button
                              variant="danger"
                              class="mt-0 ml-50 mr-0 float-left"
                              @click="removeElement(i,k)"
                            >
                            <feather-icon icon="XIcon" />
                            <!-- <span class="align-middle ml-50">Element</span> -->
                          </b-button>
                          </b-col>
                        
                        </b-row>
                      </div>

                      <b-row class="mt-1">

                        <b-col md="9" class="text-center">
                            <hr>
                            <b-button class="mt-1 mb-1" @click="addElement(i)" variant="secondary">Add Element</b-button>
                        </b-col>
                      </b-row>

                    </b-col>


                  </b-row>

                  <b-row class="mt-1">

                      <b-col md="12" class="text-center">
                          <hr>
                          <b-button class="mt-1" @click="addIU()" variant="primary">Add More Unit</b-button>
                      </b-col>
                  </b-row>

                </div>
              </div>
            </div>

            

            
            
            
            <b-row>
              <b-col>
                <b-button
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  type="submit"
                  variant="warning"
                  class="mr-1"
                >
                  Submit
                </b-button>
                <b-button
                  v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                  variant="outline-secondary"
                  @click="$router.push({ name:'forms' })"
                >
                  Cancel
                </b-button>
              </b-col>
          </b-row>


          </b-col>
        </b-row>

      </b-form>
    </b-card>
    </b-col>
  </b-row>

  
</div>
</template>

<script>

import {
  BCard, BRow, BCol, BFormGroup, BFormInput, BFormCheckbox, BForm, BButton,BAlert,BFormSelect,BFormSelectOption,BFormText,BInputGroupPrepend,BInputGroup,BMedia,BAvatar,BTable,BModal, BFormRadio,BFormTimepicker,BFormFile, BBreadcrumb
} from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'
import { GET_API, POST_API } from "../../../store/actions.type"
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.css';
import VueTimepicker from 'vue2-timepicker'
import 'vue2-timepicker/dist/VueTimepicker.css'
import vSelect from 'vue-select';

export default {
  components: {
    BAlert,
    BCard,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BForm,
    BButton,
    BFormSelect,
    BFormSelectOption,
    BFormText,
    BInputGroupPrepend,
    BInputGroup,
    BMedia,
    BAvatar,
    BTable,
    BModal,
    VueCropper,
    BFormRadio,
    BFormTimepicker,
    VueTimepicker,
    vSelect,
    BFormFile,
    BBreadcrumb
  },
  directives: {
    Ripple,
  },
  data() {
    return {

      
      error_message:null,
      showDismissibleAlert:false,

      form :{
        site : '',
        form_name:'',
        /*checklist:'no',*/
        users:[],
        file:'',
        type:'qi_checklist',
        data:[{
          unit_name:'',
          elements:[{
            name:''
          }]
        }]
      },
      sites:[],
      supervisors:[],
      selectedFile:{
        file:null,
        name:'',
      },
      bulkFile:null,
      template:'import'
      
    }
  },
  methods : {
    formSubmit(e){
      e.preventDefault();

      return this.$store.dispatch(POST_API, {
           data:{
             items:this.form,
             selectedfile:this.selectedFile,
             template:this.template,
           },
           api: '/api/add-form'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Record Added Successfully.',
                  showConfirmButton: false,
                  timer: 1500
                }).then((result) => {
                    // this.$router.go(-1);
                    this.$router.push({ name:'forms' })
                });
                
            }
        });
      
    },

    allSites(){
      return this.$store.dispatch(POST_API, {
           data:{
             role:this.$store.getters.currentUser.role,
             //om_sites:this.$store.getters.currentUser.om_sites,
           },
           api: '/api/all-sites'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                var data  = this.$store.getters.getResults.data;
                
                this.sites = data;
                return this.sites;
            }
        });
    },
    resetSupervisor(){
      this.form.users = [];
      this.allSupervisor();
      this.resetData();
    },
    allSupervisor(){
      return this.$store.dispatch(POST_API, {
           data:{
             site:this.form.site
           },
           api: '/api/all-form-users'
        })
        .then(() => {
            if (this.$store.getters.containsErrors) {
                this.error_message = this.$store.getters.getErrors;
                this.showDismissibleAlert = true;
                window.scrollTo(0,0);
            } else {
                this.showDismissibleAlert = false;
                this.supervisors  = this.$store.getters.getResults.data;
                return this.supervisors;
            }
        });
    },

    sampleDownload(){
      var url = process.env.VUE_APP_SERVER_URL + '/samplefiles/inspection_unit_sample.xlsx';
      window.open(url, '_blank');
    },
    onFileChange(event) {
            
            this.selectedFile.file = null;
            this.selectedFile.name = '';
            var input = event.target;
            
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.readAsDataURL(input.files[0]);
                this.form.file = input.files[0];
                

                if ((this.form.file.type != 'application/vnd.ms-excel') && this.form.file.type != 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') {
                    
                    //this.$refs.uploadFile.value = null;
                    this.$refs.uploadFile.reset();
                    this.selectedFile.file = null;
                    this.selectedFile.name = '';
                    
                    this.form.file="";
                    this.bulkFile = null;

                    this.showDismissibleAlert=false;
                    Swal.fire({
                      icon: 'error',
                      title: "Please upload .xls or .xlsx files",
                    })
                } else if (this.form.file.size > 2097152) {

                    this.form.file="";
                    this.selectedFile.file = null;
                    this.selectedFile.name = '';
                    this.showDismissibleAlert=false;
                    this.bulkFile = null;
                    this.$refs.uploadFile.reset();

                    Swal.fire({
                      icon: 'error',
                      title: "Maximum 20 MB files are allowed to upload"
                    })
                } else {
                    reader.onload = (e) => {
                        
                        this.selectedFile.file = e.target.result;
                        this.selectedFile.name = this.form.file.name;

                        this.showDismissibleAlert=false;
                    }
                }
            }
    },
    breadCrumb(){
      var item = [{
        to:{name:'client-dashboard'},
        text: 'Dashboard',
      },{
        to:null,
        text: 'QI & Checklist'
      },{
        to:{name:'forms'},
        text: 'Forms',
      },{
        to:null,
        text: 'Add Form',
        active:true
      }];
      return item;
    },
    resetData(){
      this.form.file="";
      this.selectedFile.file = null;
      this.selectedFile.name = '';
      this.showDismissibleAlert=false;
      this.bulkFile = null;
      /*this.$refs.uploadFile.reset();*/

      this.form.data = [{
        unit_name:'',
        elements:[{
          name:''
        }]
      }]

    },
    addIU(){
      this.form.data.push({
        unit_name:'',
        elements:[{
          name:''
        }]
      });
    },
    addElement(i){
        this.form.data[i].elements.push({name:''});
    },
    removeUnit(i){
      this.form.data.splice(i,1);

      if (this.form.data.length == 0) {
        this.addIU();
      }
    },
    removeElement(i,j){
      this.form.data[i].elements.splice(j,1);

      if (this.form.data[i].elements.length == 0) {
        this.addElement(i);
      }
    }
    

  },
  mounted(){
    this.allSites();
  }
}
</script>
